import {EnvironmentName} from 'src/app/core/constants/common.enum';

export const environment = {
  production: false,
  name: EnvironmentName.dev,
  baseApiUrl: 'https://api.dev.rashmisbakery.com',
  socketUrl: 'wss://api.dev.rashmisbakery.com:9000',
  posUrl: 'https://dev.rashmisbakery.com',
  customerUrl: 'https://dev.rashmisbakery.com',
  currentOrderLength: 5,
  minOrderNumber: 10000,
  logrocketApiKey: 'h7twdq/pos',
  analyticsApiKey: '9c6250c865ce695d7fee27ce0d3b10c1',
  pos: {
    registernumber: 1
  },
  autoPrint: true,
  customerAutoPrint: true,
  firebase: {
    apiKey: 'AIzaSyC3rDq6Wkalpv2kHI0vgLs-7ZHoES7Fs_w',
    authDomain: 'rashmis-bakery-website-dev.firebaseapp.com',
    databaseURL: 'https://rashmis-bakery-website-dev.firebaseio.com',
    projectId: 'rashmis-bakery-website-dev',
    storageBucket: 'rashmis-bakery-website-dev.appspot.com',
    messagingSenderId: '813120577663',
    appId: '1:813120577663:web:01551f76c2b28efb555f4a'
  },
  // firebaseApiUrl: 'https://firebase-dev.rashmisbakery.com/api'
  firebaseApiUrl: 'https://us-central1-rashmis-bakery-website-dev.cloudfunctions.net/api',
  timeZone: 'America/New_York'
};
